/* In a file like src/assets/fonts/fonts.css */

@font-face {
  font-family: "GigaSans"; /* Give a name to your font */
  src: url("./GigaSans-Regular.otf") format("truetype"); /* Path to your .ttf file */
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "GigaSans", sans-serif;
}
