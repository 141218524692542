.App {
  text-align: center;
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes paint {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(100%, 0);
  }
  50% {
    transform: translate(100%, 100%);
  }
  75% {
    transform: translate(0, 100%);
  }
  100% {
    transform: translate(0, 0);
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 400px;
  width: 70%;
  padding: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 8px;
}
.input-label {
  display: block;
  margin-top: 5px;
  margin-bottom: 2px;
  color: black;
  font-size: 14px;
}

.input-text,
.input-date,
.select-input {
  display: block;
  width: 80%;
  padding: 8px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-file {
  border: none;
}

.submit-button {
  padding: 10px 15px;
  background-color: #d18665;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  font-weight: bold;
}

.submit-button:hover {
  background-color: #d1866580;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: default;
}

@media (max-width: 600px) {
  .form-container {
    width: 70%;
  }
}

.admin-header {
  font-size: 26px;
  text-align: center;
  color: white;
  font-weight: bold;
}

.instruction-text {
  text-align: center;
}

.form-card {
  margin: 20px auto;
  padding: 15px;
  border-radius: 10px;
  background-color: antiquewhite;
  width: 300px;
}

.card-title {
  font-weight: bold;
}

.card-actions {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.accept-button {
  width: 48%;
  background-color: green;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
}

.reject-button {
  width: 48%;
  background-color: red;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
}

.main-app-isotype {
  position: absolute;
  top: 10%;
  z-index: 2;
  width: 40%;
}

@media (max-width: 500px) {
  .main-app-isotype {
    top: 20%;
    width: 80%;
  }
}

.flip-horizontal {
  animation: flip-horizontal 0.6s forwards;
}

@keyframes flip-horizontal {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(180deg);
  }
}

.file-upload-container {
  position: relative;
  display: inline-block;
}

.file-input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: flex;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #f8f8f8;
  border-radius: 4px;
  max-width: 100px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.filename-display {
  display: inline-block;
  font-size: 12px;
  padding: 6px 8px;
}

input[type="date"] {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  min-height: 1.2em;
}

input[type="select-input"] {
  color: black;
  min-height: 1.2em;
}

/* Reset the default iOS styling on date input */
.input-date {
  -webkit-appearance: none !important;
  border-radius: 0;
  /* Add your custom styles here */
}

/* Custom styling for file upload button */
.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  /* Add your custom styles here */
}

/* Custom styling for select input */
.select-input {
  -webkit-appearance: none !important;
  /* Add your custom styles here */
}
